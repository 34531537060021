import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

import "../scss/custom.scss"

const DisclaimerPage = () => (
  <Layout>
    <SEO title="Disclaimer" />
    <div className="container" style={{marginTop: 36}}>
      <div className="success-text text-center my-3" style={{fontSize: "1.8rem"}}>
        多謝付款，我們將會稍後發出電郵確認你的付款
      </div>
      <div style={{textAlign: "center", marginBottom: 24}}>
        <Link to="/">回到主頁</Link>
      </div>
    </div>

  </Layout>
)

export default DisclaimerPage
